import useLeases from "@/graphql/hooks/leases/useLeases";
import { Leases } from "@/model/Leases";
import { formatDate } from "@/utils/formatDate";
import daysTillLeaseEndsGenerator from "@/utils/daysTillLeaseEndGenerator";
import { useContext, useEffect } from "react";
import { RenewalOfferContext } from "@/context/renewal-offer/context";
import { UseConnectResult } from "./types";

export default function useConnect(): UseConnectResult {
  const { renewalOfferState } = useContext(RenewalOfferContext);
  const { data, isLoading, error, refetch } = useLeases({
    month: renewalOfferState.month?.toUpperCase(),
    year: renewalOfferState.year ? Number(renewalOfferState.year) : undefined,
    communityId: renewalOfferState.communityId,
    excludeRecordsWithActiveOffers: true,
  });

  useEffect(() => {
    if (
      renewalOfferState.communityId &&
      renewalOfferState.month &&
      renewalOfferState.year
    ) {
      void refetch();
    }
  }, [renewalOfferState, refetch]);

  if (!data) {
    return { leases: [], isLoading, error };
  }

  const processedLeases = data.map((lease: Leases[number]) => {
    const { unit, toDate } = lease;
    const unitName = `Bldg. ${unit.building ?? ""} - Unit ${unit.name ?? ""}`;
    const unitId = unit.id ?? "";
    const communityName = unit.communityName ?? "";
    const leaseEndDate = toDate ? formatDate(toDate) : "";
    const daysTillLeaseEnd = toDate ? daysTillLeaseEndsGenerator(toDate) : 0;

    return {
      unitName,
      unitId,
      communityName,
      leaseEndDate,
      daysTillLeaseEnd,
    };
  });

  return { leases: processedLeases, isLoading, error };
}
